class ProductValidator {
  constructor(productVal) {
    this.product = productVal
  }

  validateEmptyField = () => {
    if (!this.product.name) {
      throw EvalError('상품명이 없습니다.')
    }
    if (!this.product.brand_name) {
      throw EvalError('브랜드명이 없습니다.')
    }
    if (!this.product.category_idx) {
      throw EvalError('카테고리가 없습니다.')
    }
    if (!this.product.product_no) {
      throw EvalError('제품 번호가 없습니다.')
    }
    if (!this.product.sell_type) {
      throw EvalError('판매상태가 없습니다.')
    }
    if (!this.product.max_delivery_days) {
      throw EvalError('최대배송일이 없습니다.')
    }
    if (!this.product.min_delivery_days) {
      throw EvalError('최소배송일이 없습니다.')
    }
    if (!this.product.delivery_content) {
      throw EvalError('배송정보가 없습니다.')
    }
    if (!this.product.refund_content) {
      throw EvalError('환불정보가 없습니다.')
    }
    if (!this.product.detail_html_content) {
      throw EvalError('상품 상세가 없습니다.')
    }
    if (!this.product.description) {
      throw EvalError('상품 정보가 없습니다.')
    }
    if (!this.product.price || Number.isNaN(this.product.price)) {
      throw EvalError('가격이 없습니다.')
    }
    if (!this.product.market_price || Number.isNaN(this.product.market_price)) {
      throw EvalError('권장소비자가가 없습니다.')
    }
    if (!this.product.supply_price || Number.isNaN(this.product.supply_price)) {
      throw EvalError('공급가가 없습니다.')
    }
    if (Number.isNaN(this.product.shipping_fee)) {
      throw EvalError('배송비가 없습니다.')
    }
    if (!this.product.tagby_fee_rate || Number.isNaN(this.product.tagby_fee_rate)) {
      throw EvalError('태그바이 수수료율이 없습니다.')
    }
  }
  
  validatePrice = () => {
    if (this.product.price < this.product.supply_price) {
      throw EvalError('가격이 공급가보다 작습니다.')
    }

    if (this.product.market_price < this.product.price) {
      throw EvalError('권장소비자가가 가격보다 작습니다.')
    }
  }

  validateTagbyFee = () => {
    if (this.product.tagbyFee < 0) {
      throw EvalError('태그바이 수수료가 0보다 작습니다.')
    }
    if (this.product.price - this.product.supply_price - this.product.tagby_fee < 0) {
      throw EvalError('인플루언서 수수료가 0보다 작습니다.')
    }
  }

  validate = () => {
    try {
      this.validateEmptyField()
      this.validatePrice()
      this.validateTagbyFee()
    } catch (error) {
      alert(error.message)
      return false
    }
    return true
  }
}

export default ProductValidator
