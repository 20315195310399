<template>
  <b-card>
    <b-row>
      <b-col cols="2">
        <h4 style="margin-top: 5px;">해외직배송 여부</h4>
      </b-col>
      <b-col cols="9" style="align-self: center;" class="mb-1">
        <b-form-checkbox
          class="pt-1"
          :checked="isAbroad"
          @input="toggleIsAbroad"
        />
      </b-col>
      <b-col cols="1">
        <b-button
          variant="outline-secondary"
          class="mb-1"
          @click="isVisible = !isVisible"
          >▽
          </b-button>
      </b-col>
    </b-row>

    <b-collapse
      :visible="isVisible"
    >
      <b-row>
        <b-col cols="6" class="mt-1">
          <b-form-group label="국가" label-for="abroadNation" label-cols="6" label-align="right" label-class="pr-5" label-size="lg">
            <b-input-group>
              <b-form-input
                id="abroadNation"
                :value="abroadNation"
                @input="$emit('input:nation', $event)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="성명" label-for="abroadAcceptor" label-cols="6" label-align="right" label-class="pr-5" label-size="lg">
              <b-form-input
                id="abroadAcceptor"
                :value="abroadAcceptor"
                @input="$emit('input:acceptor', $event)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" class="mt-1">
          <b-form-group label="연락처" label-for="abroadContact" label-cols="6" label-align="right" label-class="pr-5" label-size="lg">
            <b-input-group>
              <b-form-input
                id="abroadContact"
                :value="abroadContact"
                @input="$emit('input:contact', $event)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-collapse>
  </b-card>
  
</template>

<script>
import { defineComponent, ref } from "vue-demi";
import {
  BFormCheckbox, BFormGroup, BFormInput, BCard,
  BRow, BCol, BCollapse, BButton, BInputGroup,
} from 'bootstrap-vue'

export default defineComponent({
  props: {
    isAbroad: {
      type: Boolean,
      required: true,
    },
    abroadNation: {
      type: String,
    },
    abroadAcceptor: {
      type: String,
    },
    abroadContact: {
      type: String,
    },
  },

  components: {
    BCard,
    BRow,
    BCol,
    BCollapse,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
  },

  setup(props, context) {
    const isVisible = ref(false)
    const toggleIsAbroad = e => {
      context.emit('toggleIsAbroad', e)
    }

    return {
      isVisible,
      toggleIsAbroad,
    }
  },

})
</script>

<style>

</style>