<template>
  <b-card title="텍스트 옵션">
    <b-row>
      <b-col style="border: 1px solid grey" class="mb-1">
        <b-row align-v="center" class="p-1">
          <b-col cols="4">
            <b-form-group
              class="ml-1"
              label="옵션명"
              label-for="optionName"
            >
              <b-form-input
                id="optionName"
                v-model="textOptionName"
                placeholder="예) 각인"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="추가 공급가"
              label-for="addSupplyPrice"
            >
              <b-form-input
                id="addSupplyPrice"
                v-model.number="addSupplyPrice"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="추가가격" label-for="addPrice">
              <b-form-input
                id="addPrice"
                v-model.number="addPrice"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="추가 태그바이수수료" label-for="addTagbyFee">
              <b-form-input
                id="addTagbyfee"
                v-model.number="addTagbyfee"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-button
              @click="addTextOption"
              v-text="'적용'"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row
      v-for="option, i in textOptions"
      :key="i"
    >
      <b-col style="border: 1px">
        <b-row align-v="center" class="p-1">
          <b-col cols="4">
            <b-form-group
              class="ml-1"
              label="옵션명"
              label-for="optionName"
            >
              <b-form-input
                id="optionName"
                v-model="textOptions[i].name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="추가 공급가"
              label-for="addSupplyPrice"
            >
              <b-form-input
                id="addSupplyPrice"
                v-model.number="textOptions[i].add_supply_price"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="추가가격" label-for="addPrice">
              <b-form-input
                id="addPrice"
                v-model.number="textOptions[i].add_price"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="추가 태그바이수수료" label-for="addTagbyFee">
              <b-form-input
                id="addTagbyfee"
                v-model.number="textOptions[i].add_tagby_fee"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-button
              @click="$emit('delete:option', i)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row
      v-if="textOptions.length === 0"
      class="m-1"
      style="font-size: 18px;"
    >
      텍스트 옵션 없음
    </b-row>

  </b-card>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";
import {
  BCard,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue"

export default defineComponent({
  props: {
    textOptions: Array,
  },

  components: {
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
  },

  setup(props, context) {
    const textOptionName = ref('')
    const addPrice = ref(0)
    const addSupplyPrice = ref(0)
    const addTagbyfee = ref(0)
    
    const validate = () => {
      if (!textOptionName.value) {
        throw EvalError('옵션명이 없습니다.')
      }

      if (addSupplyPrice.value > addPrice.value) {
        throw EvalError('공급가가 추가가격보다 클 수는 없습니다.')
      }
    }

    const addTextOption = () => {
      // validate
      try {
        validate()
      } catch (e) {
        alert(e.message)
        return;
      }

      context.emit(
        'addTextOption',
        {
          name: textOptionName.value,
          add_price: addPrice.value,
          add_supply_price: addSupplyPrice.value,
          add_tagby_fee: addTagbyfee.value,
          type: 'TEXT',
        },
      )
      textOptionName.value = ''
      addPrice.value = 0
      addSupplyPrice.value = 0
      addTagbyfee.value = 0
    }

    return {
      textOptionName,
      addPrice,
      addSupplyPrice,
      addTagbyfee,

      addTextOption,
    }
  },
})
</script>

<style>

</style>