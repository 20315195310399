import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProductDetail(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/product/view/${idx}/`,
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateProductDetail(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/product/update/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCategoryList(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BACKEND_SERVER}/info/category/v2/`,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRequestDetail(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/product/request/${idx}/`,
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
