import { ref, computed, reactive } from '@vue/composition-api'
import _ from 'lodash'
import moment from 'moment'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import router from '@/router'
import getCommaNumber from '@/hooks/getCommaNumber'

export default function ProductViewModel() {
  const product = ref({
    additional_shipping_fee_cond: '{}',
    brand_name: '',
    buy_limit_cond: '{}',
    category_idx: 1,
    delivery_content: '',
    description: '',
    detail_html_content: '<p>상품상세정보입니다<p>',
    end_at: undefined,
    market_price: 0,
    max_bundle_count: 0,
    max_delivery_days: 0,
    min_delivery_days: 0,
    name: '',
    origin_info_json: '{}',
    price: 0,
    product_no: '',
    refund_content: '',
    sell_type: 'RECRUITMENT',
    shipping_fee: 0,
    shipping_fee_type: 'PRE_PAY',
    shipping_free_cond: '{}',
    start_at: undefined,
    state: 'CONFIRMING',
    stock: 0,
    supply_price: 0,
    tagby_fee: 0,
    tagby_fee_rate: 0.07,
    tagby_product_no: '',
    thumbnail_img_url: '',
    vat_rate: 0.1,
    vendor_idx_id: 1,
  })
  const categoryList = ref([])
  const vendor = ref({})
  const additionalShippingFeeCond = ref({ jeju: 0, island_mountain: 0 })
  const buyLimitCond = ref({})
  const optionType = ref('COMB')
  const baseOptions = ref([])
  const additionalOptions = ref([])
  const additionalOptionDepthList = ref([
    { category: '', rawName: '' },
  ])
  const selectedBaseOptionIndices = ref([false])
  const selectedAdditionalOptionIndices = ref([false])
  const isOptionRebuild = ref(false)
  const baseOptionDepthList = ref([
    { category: '', rawName: '' },
  ])
  const textOptions = ref([])

  const price = ref(0)
  const supplyPrice = ref(0)
  const marketPrice = ref(0)
  const shippingFee = ref(0)
  const jejuShippingFee = ref(0)
  const mountainShippingFee = ref(0)
  const shippingFreeCond = ref({})
  const shippingFreeCondGte = ref('')
  const priceGroup = reactive({
    price,
    supplyPrice,
    marketPrice,
  })
  const shippingFeeGroup = reactive({
    shippingFee,
    jejuShippingFee,
    mountainShippingFee,
    shippingFreeCondGte,
  })
  const tagbyFeeRef = reactive({
    tagbyFeeRate: 7,
    tagbyFee: computed(() => Math.round((tagbyFeeRef.tagbyFeeRate / 100 + 0.03) * (product.value.shipping_fee + product.value.price))),
    infFee: computed(() => Math.floor((product.value.price - product.value.supply_price - tagbyFeeRef.tagbyFee) * 10 / 11)),
    etcBenefit: computed(() => (price.value - supplyPrice.value - tagbyFeeRef.tagbyFee - tagbyFeeRef.infFee)),
  })
  const isAbroad = ref(false)
  const abroadNation = ref('')
  const abroadAcceptor = ref('')
  const abroadContact = ref('')

  const originalImg = ref()
  const originalImgUrl = ref()

  const isCropModalOpened = ref(false)
  const isDisabled = ref({
    updateButton: false,
  })
  const hasStartDate = ref(false)
  const hasEndDate = ref(false)
  const imgUrlList = ref([])

  // options
  const fromFormtoOptions = (baseOptionForms, optionType) => {
    if (optionType === 'SINGLE') {
      return baseOptionForms
    }
    return fromFormToBaseOptions(baseOptionForms)
  }

  const fromFormToBaseOptions = baseOptionForms => {
    if (baseOptionForms.length === 0) {
      return []
    }

    const processedOptions = []
    // arr: { category: string; name: string; type: "CHOICE" | "TEXT" }[],
    // children: BaseOptionForm[]
    const build = (arr, children) => {
      for (const option of children) {
        const depth = [
          ...arr.slice(0),
          { category: option.category, name: option.name, type: option.type },
        ]

        if (!option.child_options || option.child_options.length === 0) {
          const newOption = {
            depth,
            idx: option.idx,
            add_price: option.add_price,
            add_supply_price: option.add_supply_price,
            add_tagby_fee: option.add_tagby_fee,
            stock: option.stock,
          }

          processedOptions.push(newOption)
        } else {
          build(depth, option.child_options)
        }
      }
    }

    build([], baseOptionForms)

    return processedOptions
  }

  const getSingleTypeCategoriesFromOptions = baseOptions => {
    const singleTypeCategories = []
    if (baseOptions.length === 0) {
      return [{ category: '', rawName: '' }]
    }

    const optionMap = new Map()
    baseOptions.forEach(option => {
      const { category, name } = option
      if (!optionMap.has(category)) {
        optionMap.set(category, name)
      } else {
        const rawName = optionMap.get(category)
        optionMap.set(category, `${rawName},${name}`)
      }
    })

    optionMap.forEach((value, key) => {
      singleTypeCategories.push({ category: key, rawName: value })
    })

    return singleTypeCategories
  }

  const getBaseOptionDepthFromBaseOptions = baseOptions => {
    if (baseOptions.length === 0) {
      return [{ category: '', rawName: '' }]
    }

    const depth = baseOptions[0].depth.length

    let depthList = []

    for (let i = 0; i < depth; i++) {
      const curCategory = baseOptions[0].depth[i].category
      const curNames = baseOptions.reduce((acc, cur) => {
        if (!acc.includes(cur.depth[i].name)) {
          acc.push(cur.depth[i].name)
        }

        return acc
      }, [])

      const newDepthObj = {
        category: curCategory,
        rawName: curNames.join(','),
      }

      depthList = [...depthList, newDepthObj]
    }

    return depthList
  }

  const inputBaseOptionDepth = (value, idx, key) => {
    baseOptionDepthList.value[idx][key] = value
  }

  const addBaseOptionDepth = () => {
    baseOptionDepthList.value.push({ category: '', rawName: '' })
  }

  const deleteBaseOptionDepth = index => {
    baseOptionDepthList.value.splice(index, 1)
    if (baseOptionDepthList.value.length === 0) {
      baseOptionDepthList.value.push({ category: '', rawName: '' })
    }
  }

  const getUniqueOptionNames = rawName => {
    const names = rawName
      .split(',')
      .filter(name => name.length > 0)
      .map(name => name.trim())

    const uniqueNames = [...new Set(names)]
    return uniqueNames
  }

  const toggleAllBaseOptions = e => {
    if (e === true) {
      selectedBaseOptionIndices.value = Array.from({ length: baseOptions.value.length }, () => true)
    } else {
      selectedBaseOptionIndices.value = Array.from({ length: baseOptions.value.length }, () => false)
    }
  }

  const toggleAllAdditionalOptions = e => {
    if (e === true) {
      selectedAdditionalOptionIndices.value = Array.from({ length: additionalOptions.value.length }, () => true)
    } else {
      selectedAdditionalOptionIndices.value = Array.from({ length: additionalOptions.value.length }, () => false)
    }
  }

  const deleteBaseOptions = () => {
    const sortedTargetIndices = []
    for (const [i, e] of selectedBaseOptionIndices.value.entries()) {
      if (e === true) {
        sortedTargetIndices.push(i)
      }
    }
    sortedTargetIndices.sort((a, b) => b - a)

    for (const index of sortedTargetIndices) {
      baseOptions.value.splice(index, 1)
    }

    baseOptionDepthList.value = getBaseOptionDepthFromBaseOptions(
      baseOptions.value,
    )
    selectedBaseOptionIndices.value = Array.from({ length: baseOptions.value.length }, () => false)
    isOptionRebuild.value = true
  }

  const deleteAllBaseOptions = () => {
    baseOptions.value = []
  }

  const createBaseOptions = () => {
    if (optionType.value === 'SINGLE') {
      createSingleTypeBaseOption()
    } else {
      createCombTypeBaseOptions()
    }
    isOptionRebuild.value = true
  }

  const createSingleTypeBaseOption = () => {
    baseOptions.value = []
    baseOptionDepthList.value.forEach(({ category, rawName }) => {
      const names = rawName.split(',')

      names.forEach(name => {
        baseOptions.value.push({
          category,
          name,
          type: 'CHOICE',
          price: 0,
          add_price: 0,
          add_supply_price: 0,
          stock: 0,
        })
      })
    })
  }

  const createCombTypeBaseOptions = () => {
    const cases = []
    const maxDepth = baseOptionDepthList.value.length - 1

    const processedDepth = baseOptionDepthList.value.map(
      ({ category, rawName }) => {
        if (!category | !rawName) {
          throw EvalError('필수값을 넣어주세요')
        }
        return {
          category,
          names: getUniqueOptionNames(rawName),
        }
      },
    )

    // cartesian product
    const cartesianOptions = (arr, i) => {
      const l = processedDepth[i].names.length
      for (let j = 0; j < l; j++) {
        const a = arr.slice(0)
        a.push({
          category: processedDepth[i].category,
          name: processedDepth[i].names[j],
          type: 'CHOICE',
        })

        if (i === maxDepth) {
          cases.push(a)
        } else {
          cartesianOptions(a, i + 1)
        }
      }
    }

    cartesianOptions([], 0)

    baseOptions.value = cases.map(value => ({
      depth: _.cloneDeep(value),
      add_price: 0,
      add_supply_price: 0,
      stock: 0,
    }))

    baseOptionDepthList.value = processedDepth.map(({ category, names }) => ({
      category,
      rawName: names.join(','),
    }))

    // baseOptionTableHeaders.value = baseOptionDepthList.value.map(({ category }) => category);
  }

  const fromBaseOptionsToForm = (baseOptions, optionType) => {
    if (optionType === 'SINGLE') {
      return baseOptions
    }
    return fromCombTypeOptionsToForm(baseOptions)
  }

  const fromCombTypeOptionsToForm = baseOptions => {
    if (baseOptions.length === 0) {
      return []
    }

    const depth = baseOptions[0].depth.length
    const { length } = baseOptions

    const build = (
      children,
      optionIndex,
      curDepth,
    ) => {
      if (curDepth === depth - 1) {
        const curBaseOptionForm = {
          category: baseOptions[optionIndex].depth[curDepth].category,
          name: baseOptions[optionIndex].depth[curDepth].name,
          type: baseOptions[optionIndex].depth[curDepth].type,
          add_price: baseOptions[optionIndex].add_price,
          add_tagby_fee: baseOptions[optionIndex].add_tagby_fee,
          add_supply_price: baseOptions[optionIndex].add_supply_price,
          stock: baseOptions[optionIndex].stock,
        }

        children.push(curBaseOptionForm)
        return
      }

      const matchedOption = children.find(
        option => option.name === baseOptions[optionIndex].depth[curDepth].name,
      )

      if (!matchedOption) {
        const curBaseOptionForm = {
          category: baseOptions[optionIndex].depth[curDepth].category,
          name: baseOptions[optionIndex].depth[curDepth].name,
          type: baseOptions[optionIndex].depth[curDepth].type,
          add_price: 0,
          add_supply_price: 0,
          add_tagby_fee: 0,
          child_options: [],
        }

        children.push(curBaseOptionForm)
        build(curBaseOptionForm.child_options, optionIndex, curDepth + 1)
      } else if (matchedOption.child_options) {
        build(matchedOption.child_options, optionIndex, curDepth + 1)
      }
    }

    const baseOptionForms = []

    for (let i = 0; i < length; i++) {
      build(baseOptionForms, i, 0)
    }

    return baseOptionForms
  }

  const addAdditionalOptionDepth = () => {
    additionalOptionDepthList.value.push({ category: '', rawName: '' })
  }

  const deleteAdditionalOptionDepth = index => {
    additionalOptionDepthList.value.splice(index, 1)
    if (additionalOptionDepthList.value.length === 0) {
      additionalOptionDepthList.value.push({ category: '', rawName: '' })
    }
  }

  const inputAdditionalOptionDepth = (value, idx, key) => {
    additionalOptionDepthList.value[idx][key] = value
  }

  const createAdditionalOptions = () => {
    additionalOptions.value = []
    additionalOptionDepthList.value.forEach(({ category, rawName }) => {
      const names = rawName.split(',')

      names.forEach(name => {
        additionalOptions.value.push({
          category,
          name,
          type: 'CHOICE',
          price: 0,
          add_price: 0,
          add_supply_price: 0,
          stock: 0,
        })
      })
    })
  }

  const deleteAdditionalOptions = () => {
    const sortedTargetIndices = []
    for (const [i, e] of selectedAdditionalOptionIndices.value.entries()) {
      if (e === true) {
        sortedTargetIndices.push(i)
      }
    }
    sortedTargetIndices.sort((a, b) => b - a)

    for (const index of sortedTargetIndices) {
      additionalOptions.value.splice(index, 1)
    }

    selectedAdditionalOptionIndices.value = Array.from({ length: additionalOptions.value.length }, () => false)
    isOptionRebuild.value = true
  }

  const deleteAllAdditionalOptions = () => {
    baseOptions.value = []
    isOptionRebuild.value = true
  }

  const changeOptionType = type => {
    optionType.value = type

    isOptionRebuild.value = true
    createBaseOptions()
  }

  const addTextOption = textOption => {
    isOptionRebuild.value = true
    textOptions.value.push(textOption)
  }

  const deleteTextOption = i => {
    textOptions.value.splice(i, 1)
  }

  // option end

  const convertAdditionalShippingFeeCond = (product, additionalShippingFeeCond) => {
    let jeju = 0
    let island_mountain = 0
    if (additionalShippingFeeCond.value.jeju || !Number.isNaN(additionalShippingFeeCond.value.jeju)) {
      jeju = additionalShippingFeeCond.value.jeju
    }
    if (additionalShippingFeeCond.value.island_mountain || !Number.isNaN(additionalShippingFeeCond.value.island_mountain)) {
      island_mountain = additionalShippingFeeCond.value.island_mountain
    }
    product.value.additional_shipping_fee_cond = JSON.stringify({
      jeju, island_mountain,
    })
  }

  const convertShippingFreeCond = (product, shippingFreeCond) => {
    if (!shippingFreeCond.value?.gte || Number.isNaN(shippingFreeCond.value?.gte)) {
      product.value.shipping_free_cond = JSON.stringify({})
    } else {
      product.value.shipping_free_cond = JSON.stringify({ gte: shippingFreeCond.value.gte })
    }
  }

  const convertBuyLimitCond = (product, buyLimitCond) => {
    if (!buyLimitCond.value.lte) {
      product.value.buy_limit_cond = '{}'
    } else {
      product.value.buy_limit_cond = JSON.stringify({
        lte: parseInt(buyLimitCond.value.lte, 10),
      })
    }
  }

  const convertSellingDate = (product, hasStartDate, hasEndDate) => {
    if (hasStartDate.value === false) {
      delete product.value.start_at
    } else {
      // korea utc+9 -> utc+0
      product.value.start_at = moment(product.value.start_at)
        .utc()
        .format('YYYY-MM-DD HH:mm')
    }

    if (hasEndDate.value === false) {
      delete product.value.end_at
    } else {
      product.value.end_at = moment(product.value.end_at)
        .utc()
        .format('YYYY-MM-DD HH:mm')
    }
  }

  const convertTagbyFee = (product, tagbyFeeRef) => {
    product.value.tagby_fee = tagbyFeeRef.tagbyFee
    product.value.tagby_fee_rate = tagbyFeeRef.tagbyFeeRate / 100
  }

  // image
  const uploadImg = async e => {
    const uploadedImg = e.target.files[0]

    const reader = new FileReader()
    reader.onload = event => {
      if (event.target) {
        const imgUrl = event.target.result
        const imagePre = new Image()
        imagePre.src = imgUrl

        imagePre.onload = () => {
          originalImg.value = uploadedImg
          originalImgUrl.value = imgUrl
          isCropModalOpened.value = true
        }
      }
    }
    reader.readAsDataURL(uploadedImg)

    e.target.value = null
  }

  const updateImg = async (imgFile, purpose, idx = undefined) => {
    const file_info = {
      name: imgFile.name,
      type: imgFile.type,
      size: imgFile.size,
    }
    const formData = new FormData()
    formData.append('file', imgFile)
    formData.append(
      'file_info',
      JSON.stringify(file_info),
    )
    formData.append('purpose', purpose)
    const infoTable = 'product_info'
    let url = `${process.env.VUE_APP_ASYNC_BACKEND_SERVER}/file/upload/${infoTable}/`
    if (idx !== undefined) {
      url += `${idx}/`
    }
    const result = await axios.post(url, formData)
      .then(res => {
        const img_url = res.data.result.file_info.file_url
        if (purpose === 'THUMBNAIL') {
          product.value.thumbnail_img_url = img_url
        }
        alert('성공적으로 이미지를 업로드했습니다.')
        return img_url
      }).catch(err => {
        alert('이미지 업로드에 실패했습니다.')
        console.log(err.data)
        return ''
      })

    if (result) {
      imgUrlList.value.push(result)
    }
    return result
  }

  const pageRefresh = () => { router.go() }

  // watch, alert
  const toast = useToast()

  const copyText = text => {
    if (text === product.value.tagby_product_no) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Copy success',
          icon: 'BellIcon',
          variant: 'primary',
        },
      })
    } else {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Copy Failed',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  // modal
  const openCropModal = () => {
    isCropModalOpened.value = true
  }

  const closeCropModal = () => {
    isCropModalOpened.value = false
  }

  const changeVendor = vendorData => {
    vendor.value = vendorData
    product.value.vendor_idx_id = vendorData.idx
  }

  // set date
  const setStartDate = () => {
    hasStartDate.value = true
    if (!product.value.start_at) {
      product.value.start_at = moment(new Date()).format('YYYY-MM-DD HH:mm')
    }
  }

  const deleteStartDate = () => {
    hasStartDate.value = false
    product.value.start_at = ''
  }

  const setEndDate = () => {
    hasEndDate.value = true
    if (!product.value.end_at) {
      product.value.end_at = moment(new Date()).format('YYYY-MM-DD HH:mm')
    }
  }

  const deleteEndDate = () => {
    hasEndDate.value = false
    product.value.end_at = ''
  }

  // formatted and validation
  const formattedPrice = computed(() => {
    if (price.value !== null) {
      return getCommaNumber(price.value, 3)
    }
    return ''
  })

  const formattedSupplyPrice = computed(() => {
    if (supplyPrice.value != null) {
      return getCommaNumber(supplyPrice.value, 3)
    }
    return ''
  })

  const formattedMarketPrice = computed(() => {
    if (marketPrice.value != null) {
      return getCommaNumber(marketPrice.value, 3)
    }
    return ''
  })

  const formattedShippingFee = computed(() => {
    if (shippingFee.value != null) {
      return getCommaNumber(shippingFee.value, 3)
    }
    return ''
  })

  const formattedJejuShippingFee = computed(() => {
    if (jejuShippingFee.value != null) {
      return getCommaNumber(jejuShippingFee.value, 3)
    }
    return ''
  })

  const formattedMountainShippingFee = computed(() => {
    if (mountainShippingFee.value != null) {
      return getCommaNumber(mountainShippingFee.value, 3)
    }
    return ''
  })

  const formattedShippingFreeCond = computed(() => {
    if (shippingFreeCondGte.value !== null) {
      return getCommaNumber(shippingFreeCondGte.value, 3)
    }
    return ''
  })

  const inputPrice = (formattedPrice, key) => {
    const stringNumber = formattedPrice.replaceAll(',', '')
    const numberPrice = stringNumber === '' ? null : Number(stringNumber)

    priceGroup[key] = numberPrice
  }

  const setPrice = (e, key) => {
    switch (key) {
      case 'marketPrice':
        product.value.market_price = priceGroup[key]
        break
      case 'price':
        product.value.price = priceGroup[key]
        break
      case 'supplyPrice':
        product.value.supply_price = priceGroup[key]
        break
      default:
        break
    }

    e.target.classList.add('border-secondary')
  }

  const setTagbyFeeRate = e => {
    tagbyFeeRef.tagbyFeeRate = e.target.value
    e.target.classList.add('border-secondary')
  }

  const inputShippingFee = (formattedPrice, key) => {
    const stringNumber = formattedPrice.replaceAll(',', '')
    const numberPrice = stringNumber === '' ? null : Number(stringNumber)

    shippingFeeGroup[key] = numberPrice
  }

  const setShippingFee = (e, key) => {
    switch (key) {
      case 'shippingFee':
        product.value.shipping_fee = shippingFeeGroup[key]
        break
      case 'jejuShippingFee':
        additionalShippingFeeCond.value.jeju = shippingFeeGroup[key]
        break
      case 'mountainShippingFee':
        additionalShippingFeeCond.value.island_mountain = shippingFeeGroup[key]
        break
      case 'shippingFreeCondGte':
        shippingFreeCond.value.gte = shippingFeeGroup[key]
        break
      default:
        break
    }

    e.target.classList.add('border-secondary')
  }

  const changeHtml = e => {
    product.value.detail_html_content = e
  }

  const changeShippingPolicy = selectedPolicy => {
    product.value.shipping_policy_idx_id = selectedPolicy.idx
    product.value.shipping_policy_name = selectedPolicy.name
  }

  // abroad
  const convertAbroadDetail = () => {
    product.value.is_abroad = isAbroad.value
    product.value.abroad_json = JSON.stringify({
      nation: abroadNation.value,
      acceptor: abroadAcceptor.value,
      contact: abroadContact.value,
    })
  }

  const toggleIsAbroad = e => {
    isAbroad.value = e
  }

  const inputNation = e => {
    abroadNation.value = e
  }

  const inputAcceptor = e => {
    abroadAcceptor.value = e
  }

  const inputContact = e => {
    abroadContact.value = e
  }

  // put mystore modal
  const mystoreQueryParams = [
    { text: '인플루언서명', value: 'inf_name[]' },
    { text: '마이스토어명', value: 'store_name[]' },
    { text: 'url', value: 'store_url[]' },
  ]

  const mystoreSearchResult = ref([])

  const mystoreSearchResultColumns = [
    {
      key: 'idx',
      label: 'idx',
    },
    {
      key: 'name',
      label: '마이스토어명',
    },
    {
      key: 'url_path',
      label: 'URL',
    },
    {
      key: 'state',
      label: '상태',
    },
    {
      key: 'select',
      label: '선택',
    },
  ]

  const searchMystoreCount = ref(0)

  const searchMystore = async queryParams => {
    try {
      const result = await axios.get(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/mystore/list/`,
        { params: queryParams },
      )
      mystoreSearchResult.value = result.data.data
      searchMystoreCount.value = result.data.page_info.total_count
    } catch (e) {
      toast({
        component: ToastificationContent,
        props: {
          title: '마이스토어 검색에 실패했습니다.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const putIntoMystore = async mystoreObj => {
    try {
      await axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/mystore/add-product/`,
        { mystore_idx: mystoreObj.idx, tagby_product_no: product.value.tagby_product_no },
      )
      toast({
        component: ToastificationContent,
        props: {
          title: '성공적으로 마이스토어에 담았습니다.',
          icon: 'BellIcon',
          variant: 'primary',
        },
      })
      router.push({ name: 'commerce-mystore-view', params: { idx: mystoreObj.idx } })
    } catch (e) {
      toast({
        component: ToastificationContent,
        props: {
          title: '마이스토어에 담기를 실패했습니다.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const toggleDutyFree = e => {
    if (e) {
      product.value.vat_rate = 0
    } else {
      product.value.vat_rate = 0.1
    }
  }

  return {
    // ref
    product,
    vendor,
    categoryList,
    originalImg,
    originalImgUrl,
    isCropModalOpened,
    additionalShippingFeeCond,
    shippingFreeCond,
    buyLimitCond,
    optionType,
    baseOptions,
    additionalOptions,
    additionalOptionDepthList,
    baseOptionDepthList,
    selectedBaseOptionIndices,
    selectedAdditionalOptionIndices,
    isOptionRebuild,
    isDisabled,
    hasStartDate,
    hasEndDate,
    imgUrlList,

    price,
    supplyPrice,
    marketPrice,
    shippingFee,
    jejuShippingFee,
    mountainShippingFee,
    shippingFreeCondGte,

    // computed
    tagbyFeeRef,
    formattedPrice,
    formattedSupplyPrice,
    formattedMarketPrice,
    formattedShippingFee,
    formattedJejuShippingFee,
    formattedMountainShippingFee,
    formattedShippingFreeCond,

    // methods
    uploadImg,
    updateImg,
    pageRefresh,
    copyText,
    openCropModal,
    closeCropModal,
    changeVendor,
    inputBaseOptionDepth,
    addBaseOptionDepth,
    deleteBaseOptionDepth,
    deleteBaseOptions,
    toggleAllBaseOptions,
    deleteAllBaseOptions,
    createBaseOptions,
    addAdditionalOptionDepth,
    deleteAdditionalOptionDepth,
    inputAdditionalOptionDepth,
    toggleAllAdditionalOptions,
    deleteAdditionalOptions,
    deleteAllAdditionalOptions,
    createAdditionalOptions,
    changeOptionType,
    setStartDate,
    deleteStartDate,
    setEndDate,
    deleteEndDate,
    inputPrice,
    setPrice,
    setTagbyFeeRate,
    inputShippingFee,
    setShippingFee,
    toggleDutyFree,

    fromFormtoOptions,
    fromFormToBaseOptions,
    getBaseOptionDepthFromBaseOptions,
    getSingleTypeCategoriesFromOptions,

    convertAdditionalShippingFeeCond,
    convertShippingFreeCond,
    convertBuyLimitCond,
    convertSellingDate,
    convertTagbyFee,
    fromBaseOptionsToForm,
    changeHtml,
    changeShippingPolicy,

    // text options
    textOptions,
    addTextOption,
    deleteTextOption,

    // abraod
    isAbroad,
    abroadNation,
    abroadAcceptor,
    abroadContact,
    convertAbroadDetail,
    toggleIsAbroad,
    inputNation,
    inputAcceptor,
    inputContact,

    // mystore modal
    putIntoMystore,
    mystoreQueryParams,
    mystoreSearchResult,
    mystoreSearchResultColumns,
    searchMystoreCount,
    searchMystore,
  }
}
