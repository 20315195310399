<template>
  <b-row>
    <b-col
      cols="12"
      sm="9"
      md="9"
      lg="10"
      xl="10"
    >
      <div>
        <CropImageModal
          v-if="isCropModalOpened"
          :idx="idx"
          :originalImg="originalImg"
          :originalImgUrl="originalImgUrl"
          @closeModal="closeCropModal"
          @updateImg="updateImg"
        />
      </div>

      <ProductContainer
        :product="product"
        :vendor="vendor"
        :categoryList="categoryList"
        :buyLimitCond="buyLimitCond"
        :tagbyFeeRef="tagbyFeeRef"
        :baseOptions="baseOptions"
        :shippingFreeCond="shippingFreeCond"
        :updateImg="updateImg"
        :hasStartDate="hasStartDate"
        :hasEndDate="hasEndDate"
        :formattedPrice="formattedPrice"
        :formattedSupplyPrice="formattedSupplyPrice"
        :formattedMarketPrice="formattedMarketPrice"
        :formattedShippingFee="formattedShippingFee"
        :formattedJejuShippingFee="formattedJejuShippingFee"
        :formattedMountainShippingFee="formattedMountainShippingFee"
        :formattedShippingFreeCond="formattedShippingFreeCond"
        @input:price="inputPrice"
        @input:shippingFee="inputShippingFee"
        @copyText="copyText"
        @changeVendor="changeVendor"
        @setStartDate="setStartDate"
        @deleteStartDate="deleteStartDate"
        @setEndDate="setEndDate"
        @deleteEndDate="deleteEndDate"
        @setPrice="setPrice"
        @setTagbyFeeRate="setTagbyFeeRate"
        @setShippingFee="setShippingFee"
        @changeHtml="changeHtml"
        @changeShippingPolicy="changeShippingPolicy"
        @toggleDutyFree="toggleDutyFree"
      />

      <ProductOptionContainer
        :isAdditional="false"
        :optionType="optionType"
        :options="baseOptions"
        :optionDepthList="baseOptionDepthList"
        :selectedOptionIndices="selectedBaseOptionIndices"
        @create:options="createBaseOptions"
        @add:optionDepth="addBaseOptionDepth"
        @delete:optionDepth="deleteBaseOptionDepth"
        @toggleAllOptions="toggleAllBaseOptions"
        @delete:options="deleteBaseOptions"
        @delete:allOptions="deleteAllBaseOptions"
        @input:depth="inputBaseOptionDepth"
        @changeOptionType="changeOptionType"
      />

      <!-- <ProductOptionContainer
        :isAdditional="true"
        :optionType="'SINGLE'"
        :options="additionalOptions"
        :optionDepthList="additionalOptionDepthList"
        :selectedOptionIndices="selectedAdditionalOptionIndices"
        @create:options="createAdditionalOptions"
        @add:optionDepth="addAdditionalOptionDepth"
        @delete:optionDepth="deleteAdditionalOptionDepth"
        @toggleAllOptions="toggleAllAdditionalOptions"
        @delete:options="deleteAdditionalOptions"
        @delete:allOptions="deleteAllAdditionalOptions"
        @input:depth="inputAdditionalOptionDepth"
      /> -->

      <!-- <TextOptionContainer
        :textOptions="textOptions"
        @addTextOption="addTextOption"
        @delete:option="deleteTextOption"
      /> -->

      <ProductAbroadDetail
        :isAbroad="isAbroad"
        :abroadNation="abroadNation"
        :abroadAcceptor="abroadAcceptor"
        :abroadContact="abroadContact"
        @toggleIsAbroad="toggleIsAbroad"
        @input:nation="inputNation"
        @input:acceptor="inputAcceptor"
        @input:contact="inputContact"
      />
    </b-col>
    <b-col
      cols="12"
      sm="3"
      md="3"
      lg="2"
      xl="2"
    >
      <b-card>
        <b-button
          variant="primary"
          class="mb-1"
          block
          :disabled="isDisabled.updateButton"
          @click="updateProductDetail"
          v-text="$t('SAVE')"
        />
        <!-- 가상 input -->
        <input
          id="file"
          style="display:none;"
          accept="image/*"
          type="file"
          @change="uploadImg"
        >
        <b-button
          variant="primary"
          style="cursor: default;"
          block
        >
          <label
            for="file"
            style="height: 100%; color: white; cursor:pointer;"
          >
            썸네일 바꾸기
          </label>
        </b-button>
      </b-card>

      <b-card>
        <b-button
          v-b-modal.select-mystore
          variant="primary"
          block
          :disabled="isDisabled.updateButton"
          v-text="'마이스토어에 담기'"
        />
      </b-card>
      <SearchModal
        :modalId="'select-mystore'"
        :searchCategoryList="mystoreQueryParams"
        :searchResult="mystoreSearchResult"
        :searchResultColumns="mystoreSearchResultColumns"
        :totalRows="searchMystoreCount"
        @search="searchMystore"
        @ok="putIntoMystore"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  defineComponent, onUnmounted,
} from '@vue/composition-api'
import {
  BButton, BFormInput, BFormTextarea,
  BTooltip, BInputGroup, BIcon, BLink, BCard,
  BRow, BCol,
} from 'bootstrap-vue'
import moment from 'moment'

import store from '@/store'
import ProductContainer from '@/views/commerce/components/product/ProductContainer.vue'
import ProductOptionContainer from '@/views/commerce/components/product/ProductOptionContainer.vue'
import CropImageModal from '@/views/commerce/components/product/CropImageModal.vue'

import productStoreModule from './productStoreModule'
import ProductValidator from './components/product/ProductValidator'
import ProductViewModel from './ProductViewModel'
import TextOptionContainer from './components/product/TextOptionContainer.vue'
import ProductAbroadDetail from './components/product/ProductAbroadDetail.vue'
import SearchModal from './components/SearchModal.vue'

export default defineComponent({
  props: {
    idx: {
      type: [String, Number],
      required: true,
    },
  },

  components: {
    BFormInput,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BTooltip,
    BInputGroup,
    BLink,
    BIcon,

    ProductContainer,
    ProductOptionContainer,
    TextOptionContainer,
    ProductAbroadDetail,
    CropImageModal,
    SearchModal,
  },

  setup(props) {
    const {
      product,
      vendor,
      categoryList,
      originalImg,
      originalImgUrl,
      isCropModalOpened,
      additionalShippingFeeCond,
      shippingFreeCond,
      buyLimitCond,
      optionType,
      baseOptions,
      additionalOptions,
      additionalOptionDepthList,
      baseOptionDepthList,
      selectedBaseOptionIndices,
      selectedAdditionalOptionIndices,
      isOptionRebuild,
      isDisabled,
      hasStartDate,
      hasEndDate,

      price,
      supplyPrice,
      marketPrice,
      shippingFee,
      jejuShippingFee,
      mountainShippingFee,
      shippingFreeCondGte,

      // computed
      tagbyFeeRef,
      formattedPrice,
      formattedSupplyPrice,
      formattedMarketPrice,
      formattedShippingFee,
      formattedJejuShippingFee,
      formattedMountainShippingFee,
      formattedShippingFreeCond,

      // methods
      uploadImg,
      updateImg,
      pageRefresh,
      copyText,
      openCropModal,
      closeCropModal,
      changeVendor,

      addAdditionalOptionDepth,
      deleteAdditionalOptionDepth,
      toggleAllAdditionalOptions,
      deleteAdditionalOptions,
      deleteAllAdditionalOptions,
      createAdditionalOptions,
      inputAdditionalOptionDepth,

      changeOptionType,
      setStartDate,
      deleteStartDate,
      setEndDate,
      deleteEndDate,
      inputPrice,
      setPrice,
      setTagbyFeeRate,
      inputShippingFee,
      setShippingFee,
      toggleDutyFree,

      // base option
      fromFormtoOptions,
      getBaseOptionDepthFromBaseOptions,
      getSingleTypeCategoriesFromOptions,
      fromBaseOptionsToForm,
      inputBaseOptionDepth,
      addBaseOptionDepth,
      deleteBaseOptionDepth,
      deleteBaseOptions,
      toggleAllBaseOptions,
      deleteAllBaseOptions,
      createBaseOptions,

      convertAdditionalShippingFeeCond,
      convertShippingFreeCond,
      convertBuyLimitCond,
      convertSellingDate,
      convertTagbyFee,
      convertAbroadDetail,
      changeHtml,
      changeShippingPolicy,

      // text options
      textOptions,
      addTextOption,
      deleteTextOption,

      // abroad
      isAbroad,
      abroadNation,
      abroadAcceptor,
      abroadContact,
      toggleIsAbroad,
      inputNation,
      inputAcceptor,
      inputContact,

      // mystore modal
      putIntoMystore,
      mystoreQueryParams,
      mystoreSearchResult,
      mystoreSearchResultColumns,
      searchMystoreCount,
      searchMystore,
    } = ProductViewModel()

    const PRODUCT_APP_STORE_MODULE_NAME = 'app-product'
    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, productStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
      }
    })

    // get detail
    const getProductDetail = () => store.dispatch('app-product/fetchProductDetail', { idx: props.idx })
      .then(response => {
        const { data } = response.data
        product.value = data.product
        vendor.value = data.vendor

        price.value = data.product.price
        supplyPrice.value = data.product.supply_price
        marketPrice.value = data.product.market_price
        shippingFee.value = data.product.shipping_fee
        tagbyFeeRef.tagbyFeeRate = Number((product.value.tagby_fee_rate * 100).toPrecision(3))

        if (product.value.additional_shipping_fee_cond !== '{}') {
          additionalShippingFeeCond.value = JSON.parse(product.value.additional_shipping_fee_cond)
          jejuShippingFee.value = additionalShippingFeeCond.value.jeju
          mountainShippingFee.value = additionalShippingFeeCond.value.island_mountain
        }

        if (product.value.shipping_free_cond !== '{}') {
          shippingFreeCond.value = JSON.parse(product.value.shipping_free_cond)
          shippingFreeCondGte.value = shippingFreeCond.value?.gte ?? ''
        }

        if (product.value.buy_limit_cond !== '{}') {
          buyLimitCond.value = JSON.parse(product.value.buy_limit_cond)
        }
        if (product.value.start_at) {
          hasStartDate.value = true
          product.value.start_at = moment(product.value.start_at)
            .utcOffset(9)
            .format('YYYY-MM-DD HH:mm')
        }
        if (product.value.end_at) {
          hasEndDate.value = true
          product.value.end_at = moment(product.value.end_at)
            .utcOffset(9)
            .format('YYYY-MM-DD HH:mm')
        }

        // options
        optionType.value = data.options.option_type
        baseOptions.value = fromFormtoOptions(data.options.base_options, optionType.value)
        if (optionType.value === 'SINGLE') {
          baseOptionDepthList.value = getSingleTypeCategoriesFromOptions([...baseOptions.value])
        } else if (optionType.value === 'COMB') {
          baseOptionDepthList.value = getBaseOptionDepthFromBaseOptions([...baseOptions.value])
        }
        selectedBaseOptionIndices.value = Array.from({ length: baseOptions.value.length }, () => false)

        additionalOptions.value = data.options.additional_options
        additionalOptionDepthList.value = getSingleTypeCategoriesFromOptions([...additionalOptions.value])

        textOptions.value = data.options.text_options

        // aboard
        isAbroad.value = data.product.is_abroad
        if (data.product.abroad_json) {
          const abraodDetail = JSON.parse(data.product.abroad_json)
          abroadNation.value = abraodDetail.nation
          abroadAcceptor.value = abraodDetail.acceptor
          abroadContact.value = abraodDetail.contact
        }
      })
    getProductDetail()

    const getCategoryList = () => store.dispatch('app-product/getCategoryList')
      .then(res => {
        categoryList.value = res.data.data
      }).catch(err => {
        console.log(err.data)
      })
    getCategoryList()

    // update
    const updateProductDetail = () => {
      isDisabled.value.updateButton = true
      convertAdditionalShippingFeeCond(product, additionalShippingFeeCond)
      convertShippingFreeCond(product, shippingFreeCond)
      convertBuyLimitCond(product, buyLimitCond)
      convertSellingDate(product, hasStartDate, hasEndDate)
      convertTagbyFee(product, tagbyFeeRef)
      convertAbroadDetail()
      const validator = new ProductValidator(product.value)
      const validateResult = validator.validate()
      if (!validateResult) {
        isDisabled.value.updateButton = false
        return
      }
      const body = {
        idx: props.idx,
        ...product.value,
        is_option_rebuild: isOptionRebuild.value,
      }

      if (isOptionRebuild.value === true) {
        const newOptions = {
          base_options: fromBaseOptionsToForm(baseOptions.value, optionType.value),
          additional_options: additionalOptions.value,
          text_options: textOptions.value,
        }
        body.options = newOptions
      } else {
        body.options = [...baseOptions.value, ...additionalOptions.value, ...textOptions.value]
      }

      store.dispatch('app-product/updateProductDetail', body)
        .then(() => {
          alert('수정 완료하였습니다.')
          pageRefresh()
        }).catch(err => {
          alert(`수정에 실패했습니다.\n${err.response.data.message}`)
        }).finally(() => {
          isDisabled.value.updateButton = false
        })
    }

    return {
      // ref
      product,
      vendor,
      categoryList,
      originalImg,
      originalImgUrl,
      isCropModalOpened,
      additionalShippingFeeCond,
      shippingFreeCond,
      buyLimitCond,
      optionType,
      baseOptions,
      additionalOptions,
      baseOptionDepthList,
      additionalOptionDepthList,
      selectedBaseOptionIndices,
      selectedAdditionalOptionIndices,
      isDisabled,
      hasStartDate,
      hasEndDate,

      // computed
      tagbyFeeRef,
      formattedPrice,
      formattedSupplyPrice,
      formattedMarketPrice,
      formattedShippingFee,
      formattedJejuShippingFee,
      formattedMountainShippingFee,
      formattedShippingFreeCond,

      // methods
      updateProductDetail,
      uploadImg,
      updateImg,
      pageRefresh,
      copyText,
      openCropModal,
      closeCropModal,
      changeVendor,
      inputBaseOptionDepth,
      addBaseOptionDepth,
      deleteBaseOptionDepth,
      deleteBaseOptions,
      toggleAllBaseOptions,
      deleteAllBaseOptions,
      createBaseOptions,
      addAdditionalOptionDepth,
      deleteAdditionalOptionDepth,
      inputAdditionalOptionDepth,
      toggleAllAdditionalOptions,
      deleteAdditionalOptions,
      deleteAllAdditionalOptions,
      createAdditionalOptions,
      setStartDate,
      deleteStartDate,
      setEndDate,
      deleteEndDate,
      inputPrice,
      setPrice,
      setTagbyFeeRate,
      inputShippingFee,
      setShippingFee,
      changeHtml,
      changeShippingPolicy,
      changeOptionType,
      toggleDutyFree,

      // text options
      textOptions,
      addTextOption,
      deleteTextOption,

      // abroad
      isAbroad,
      abroadNation,
      abroadAcceptor,
      abroadContact,
      toggleIsAbroad,
      inputNation,
      inputAcceptor,
      inputContact,

      // mystore modal
      putIntoMystore,
      mystoreQueryParams,
      mystoreSearchResult,
      mystoreSearchResultColumns,
      searchMystoreCount,
      searchMystore,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
