<template>
  <b-card :title="isAdditional ? '추가상품' : '필수옵션'">
    <!-- 선택형 옵션 부분 -->
    <b-row>
      <b-col style="border: 1px solid grey">
        <b-row style="text-align: center;">
          <b-col class="m-1">
            <b-button
              class="mr-1"
              :variant="isSingle ? 'success' : ''"
              @click="changeOptionType('SINGLE')"
            >단독형
            </b-button>
            <b-button
              :variant="isComb ? 'success' : ''"
              :disabled="isAdditional"
              @click="changeOptionType('COMB')"
            >조합형
            </b-button>
          </b-col>
        </b-row>
        <b-row
          v-for="depth, i in optionDepthList"
          :key="i"
          align-h="center"
          class="mb-1"
        >
          <b-col cols="4">
            <b-form-group :label="'옵션' + `${i+1}`" label-for="category" label-cols="2">
              <b-form-input
                id="category"
                :value="depth.category"
                placeholder="옵션(예시: 색상)"
                @input="inputDepth($event, i, 'category')"
                @blur="isBlur"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="선택지" label-for="rawName" label-cols="2">
              <b-form-input
                id="rawName"
                :value="depth.rawName"
                placeholder="옵션명들(예시: 빨강,노랑)"
                @input="inputDepth($event, i, 'rawName')"
                @blur="isBlur"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-button
              v-if="i === optionDepthList.length-1"
              class="mr-1"
              @click="addOptionDepth"
            >+
            </b-button>
            <b-button
              @click="deleteOptionDepth(i)"
            >-
            </b-button>
          </b-col>
        </b-row>
        <b-row align-h="center" class="m-1">
          <b-button
            class="mr-1"
            @click="createOptions"
          >
            적용
          </b-button>
          <b-button
            @click="isOptionVisible = !isOptionVisible"
            v-text="isOptionVisible ? '옵션 접기' : '옵션 펼치기'"
          />
        </b-row>
      </b-col>
    </b-row>

    <template v-if="options.length > 0">
      <b-collapse :visible="isOptionVisible">
        <b-row class="pl-1 pt-1" style="border: 1px solid #0d4e74">
          <b-col cols="1">
            <b-form-checkbox
              :checked="isAllOptionSelected"
              @input="toggleAllOptions"
            />
          </b-col>
          <b-col class="ml-0">
            <b-button class="mb-1" @click="deleteOptions">선택 삭제</b-button>
          </b-col>
        </b-row>
        <b-row
          v-for="option, i in options"
          :key="option.idx"
          class="p-1 mb-1"
          style="border: 1px solid #0d4e74"
        >
          <b-col cols="1" class="pt-2">
            <b-form-checkbox
              v-model="selectedOptionIndices[i]"
            />
          </b-col>
          <!-- 조합형 -->
          <b-col v-if="optionType==='COMB'" cols="3" style="line-height: 80px;">
            {{ i+1 }}) {{ options[i].depth.map(arr => `${arr.category}: ${arr.name}`).join(' / ') }}
          </b-col>
          <!-- 단독형 -->
          <b-col v-else-if="optionType==='SINGLE'" cols="3" style="line-height: 80px;">
            {{ i+1 }}) {{ options[i].category }}: {{ options[i].name }}
          </b-col>
          <b-col cols="2">
            <b-form-group label="추가 공급가" label-for="add_supply_price">
              <b-form-input
                id="add_supply_price"
                v-model.number="options[i].add_supply_price"
                @blur="isBlur"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="추가 가격" label-for="add_price">
              <b-form-input
                id="add_price"
                v-model.number="options[i].add_price"
                @blur="isBlur"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="추가 태그바이수수료" label-for="add_tagby_fee">
              <b-form-input
                id="add_tagby_fee"
                v-model.number="options[i].add_tagby_fee"
                @blur="isBlur"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="재고" label-for="option-stock">
              <b-form-input
                id="option-stock"
                v-model.number="options[i].stock"
                @blur="isBlur"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-collapse>
    </template>

    <template v-else>
      <b-row>
        <b-col class="m-1" style="font-size: 18px;">
          {{ isAdditional ? '추가상품 없음' : '필수옵션 없음' }}
        </b-col>
      </b-row>
    </template>
    <!-- 선택형 옵션 부분 끝 -->
  </b-card>
</template>

<script>
import { defineComponent, computed, ref } from "@vue/composition-api";

import { BFormInput, BButton, BCard, BRow, BCol,
  BFormGroup, BFormCheckbox, BFormRadio, BFormRadioGroup,
  BCollapse,
} from 'bootstrap-vue'

export default defineComponent({
  props: {
    isAdditional: Boolean,
    optionType: String,
    options: Array,
    optionDepthList: Array,
    selectedOptionIndices: Array,
  },

  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BButton,
    BRow,
    BCol,
    BCollapse,
  },

  setup(props, context) {
    const isOptionVisible = ref(true)
    const optionTypeName = computed(() => {
      if (props.optionType === 'SINGLE') {
        return '단독형'
      } else if (props.optionType === 'COMB') {
        return '조합형'
      }

      return '알수없음'
    })
    const isAllOptionSelected = computed(() => props.selectedOptionIndices.every(index => index === true))

    const isSingle = computed(() => {
      return props.optionType === 'SINGLE'
    })
    const isComb = computed(() => {
      return props.optionType === 'COMB'
    })

    const addOptionDepth = () => {
      context.emit('add:optionDepth')
    }

    const deleteOptionDepth = index => {
      context.emit('delete:optionDepth', index)
    }

    const inputDepth = (e, idx, key) => {
      context.emit('input:depth', e, idx, key)
    }

    const createOptions = () => {
      context.emit('create:options')
    }

    const toggleAllOptions = e => {
      context.emit('toggleAllOptions', e)
    }

    const deleteOptions = () => {
      context.emit('delete:options')
    }

    const changeOptionType = type => {
      context.emit('changeOptionType', type)
    }

    const isBlur = e => {
      e.target.classList.add('border-secondary')
    }

    return {
      isOptionVisible,
      isSingle,
      isComb,
      optionTypeName,
      isAllOptionSelected,
      addOptionDepth,
      toggleAllOptions,
      deleteOptionDepth,
      deleteOptions,
      inputDepth,
      createOptions,

      changeOptionType,
      isBlur,
    }
  },

})
</script>

<style>

</style>