<template>
  <b-card :title="title">
    <b-row class="mb-2">
      <b-col cols="9">
        <b-row>
          <b-col
            cols="6"
          >
            <b-form-group
              label="상품명"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="product.name"
                placeholder="상품명"
                @blur="isBlur"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="브랜드명"
              label-for="brand_name"
            >
              <b-form-input
                id="brand_name"
                v-model="product.brand_name"
                placeholder="브랜드명"
                @blur="isBlur"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="idx"
              label-for="idx"
            >
              <b-form-input
                id="idx"
                v-model="product.idx"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="태그바이 품번"
              label-for="tagby_product_no"
            >
              <b-input-group>
                <b-form-input
                  id="tagby_product_no"
                  v-model="product.tagby_product_no"
                  readonly
                  placeholder="태그바이 품번"
                />
                <b-input-group-append
                  v-clipboard:copy="product.tagby_product_no"
                  is-text
                  class="cursor-pointer copy-button"
                  @click="copyText(product.tagby_product_no)"
                >
                  <feather-icon
                    icon="CopyIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label>카테고리</label>
              <v-select
                id="category"
                v-model="product.category_idx"
                :options="categoryList"
                :reduce="category => category.value"
                class="invoice-filter-select"
                placeholder="검색 항목 선택"
                @blur="isBlur"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="제품번호"
              label-for="product_no"
            >
              <b-form-input
                id="product_no"
                v-model="product.product_no"
                placeholder="제품번호"
                @blur="isBlur"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2">
        <b-img
          height="206"
          class="d-inline-block"
          :src="product.thumbnail_img_url"
          alt="썸네일이 없습니다."
          rounded
          stlye="max-height: 206px;"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
          label="벤더 회사명"
          label-for="company_name"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-form-input
            id="company_name"
            v-model="vendor.company_name"
            readonly
            style="color: #0078FE; cursor:pointer; opacity:1"
            @click="toAdvDetail(vendor.idx)"
            @blur="isBlur"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          id="vendor"
          label="벤더 이름"
          label-for="vendor"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group>
            <b-form-input
              id="vendor_name"
              v-model="vendor.name"
              readonly
              style="color: #0078FE; cursor:pointer; opacity:1"
              @click="toAdvDetail(vendor.idx)"
              @blur="isBlur"
            />
            <b-input-group-append
              v-b-modal.select-vendor
              class="cursor-pointer"
            ><b-button variant="outline-secondary">
              교체
            </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- modal vertical center -->
      <SearchModal
        :modalId="'select-vendor'"
        :searchCategoryList="searchCategoryList"
        :searchResult="searchResult"
        :searchResultColumns="searchResultColumns"
        :totalRows="searchVendorCount"
        :isBusy="isBusy"
        @search="searchVendor"
        @ok="changeVendor"
      />
    </b-row>

    <b-row>
      <b-col
        cols="6"
      >
        <b-form-group
          label="샘플 구매 한도"
          label-for="buyLimitCond"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="개">
            <b-form-input
              id="max_delivery_days"
              v-model.number="buyLimitCond.lte"
              @blur="isBlur"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="재고"
          label-for="stock"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="개">
            <b-tooltip
              target="stock"
              triggers="hover"
            >
              옵션 재고들의 합
            </b-tooltip>
            <b-form-input
              id="stock"
              v-model="product.stock"
              class="price-input"
              :readonly="baseOptions.length > 0"
              @blur="isBlur"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="판매 타입"
          label-for="sell_type"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-form-select
            id="sell_type"
            v-model="product.sell_type"
            :options="sellTypeList"
            @blur="isBlur"
          />
        </b-form-group>
        <b-form-group
          label="판매 상태"
          label-for="state"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-form-select
            id="state"
            v-model="product.state"
            :value="product.state"
            :options="stateList"
            @blur="isBlur"
          />
        </b-form-group>
        <b-form-group
          id="start_at"
          label="출시일"
          label-for="start_at"
          label-cols="6"
          label-align="right"
          label-class="pr-5 pt-2"
          label-size="lg"
        >
          <b-form-checkbox
            class="mb-1"
            :checked="hasStartDate"
            @input="setStartDate($event)"
          >
            지정
          </b-form-checkbox>
          <flat-pickr
            v-model="product.start_at"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
          />
        </b-form-group>
        <b-form-group
          id="end_at"
          label="판매종료일"
          label-for="end_at"
          label-cols="6"
          label-align="right"
          label-class="pr-5 pt-2"
          label-size="lg"
        >
          <b-form-checkbox
            class="mb-1"
            :checked="hasEndDate"
            @input="setEndDate($event)"
          >
            지정
          </b-form-checkbox>
          <flat-pickr
            v-model="product.end_at"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
          />
        </b-form-group>
        <b-form-group
          label="최소/최대 배송일"
          label-for="min_delivery_days"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="일">
            <b-form-input
              id="min_delivery_days"
              v-model.number="product.min_delivery_days"
              @blur="isBlur"
            />
            <b-form-input
              id="max_delivery_days"
              v-model.number="product.max_delivery_days"
              @blur="isBlur"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          id="shipping_free_cond"
          label="무료배송비 조건"
          label-for="gte"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="원 이상">
            <b-form-input
              id="gte"
              :value="formattedShippingFreeCond"
              @input="$emit('input:shippingFee', $event, 'shippingFreeCondGte')"
              @blur="setShippingFee($event, 'shippingFreeCondGte')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="공급가"
          label-for="supply_price"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="원">
            <b-form-input
              id="supply_price"
              :value="formattedSupplyPrice"
              @input="$emit('input:price', $event, 'supplyPrice')"
              @blur="setPrice($event, 'supplyPrice')"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="가격"
          label-for="price"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="원">
            <b-form-input
              id="price"
              :value="formattedPrice"
              @input="$emit('input:price', $event, 'price')"
              @blur="setPrice($event, 'price')"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="권장소비자가"
          label-for="market_price"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="원">
            <b-form-input
              id="market_price"
              :value="formattedMarketPrice"
              @input="$emit('input:price', $event, 'marketPrice')"
              @blur="setPrice($event, 'marketPrice')"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="태그바이수수료율"
          label-for="tagbyFeeRate"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="%">
            <b-form-input
              id="tagbyFeeRate"
              :value="tagbyFeeRef.tagbyFeeRate"
              @blur="setTagbyFeeRate"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="태그바이 수수료"
          label-for="tagbyFee"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="원">
            <b-tooltip
              target="tagbyFee"
              triggers="hover"
            >
              (가격 + 배송비) * (태그바이수수료율 + 0.03)
            </b-tooltip>
            <b-form-input
              id="tagbyFee"
              v-model="(tagbyFeeRef.tagbyFee).toLocaleString()"
              class="price-input"
              readonly
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="셀러 수수료"
          label-for="infFee"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="원">
            <b-tooltip
              target="infFee"
              triggers="hover"
            >
              (가격 - 공급가 - 태그바이수수료) * 10/11
            </b-tooltip>
            <b-form-input
              id="infFee"
              v-model="(tagbyFeeRef.infFee).toLocaleString()"
              class="price-input"
              readonly
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="영업외 수익"
          label-for="etcBenefit"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="원">
            <!-- <b-tooltip
              target="etcBenefit"
              triggers="hover"
            >
              (가격 - 공급가 - 태그바이수수료) * 10/11
            </b-tooltip> -->
            <b-form-input
              id="etcBenefit"
              :value="(tagbyFeeRef.etcBenefit).toLocaleString()"
              class="price-input"
              readonly
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="배송비"
          label-for="shipping_fee"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="원">
            <b-form-input
              id="shipping_fee"
              :value="formattedShippingFee"
              @input="$emit('input:shippingFee', $event, 'shippingFee')"
              @blur="setShippingFee($event, 'shippingFee')"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="제주 추가배송비"
          label-for="jeju"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="원">
            <b-form-input
              id="jeju"
              :value="formattedJejuShippingFee"
              @input="$emit('input:shippingFee', $event, 'jejuShippingFee')"
              @blur="setShippingFee($event, 'jejuShippingFee')"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="도서산간 추가배송비"
          label-for="island_mountain"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="원">
            <b-form-input
              id="island_mountain"
              :value="formattedMountainShippingFee"
              @input="$emit('input:shippingFee', $event, 'mountainShippingFee')"
              @blur="setShippingFee($event, 'mountainShippingFee')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
          id="shipping_policy"
          label="합배송 그룹"
          label-for="shipping-policy"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group>
            <b-form-input
              id="shipping-policy"
              v-model="product.shipping_policy_name"
              readonly
              @blur="isBlur"
            />
            <b-input-group-append
              v-b-modal.shipping-policy
              class="cursor-pointer"
              @click="getShippingPolicy(1, 10)"
            ><b-button variant="outline-secondary">
              설정
            </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- modal vertical center -->
      <shipping-policy-modal
        :modalId="'select-vendor'"
        :shippingPolicyList="shippingPolicyList"
        :policyCount="policyCount"
        :vendorIdx="vendor.idx"
        @getShippingPolicy="getShippingPolicy"
        @changeShippingPolicy="changeShippingPolicy"
      />

      <b-col cols="6">
        <b-form-group
          label="최대묶음 갯수"
          label-for="maxbundleCount"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-input-group append="개">
            <b-form-input
              id="maxbundleCount"
              v-model.number="product.max_bundle_count"
              @blur="isBlur"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
          label="면세 상품"
          label-for="maxbundleCount"
          label-cols="6"
          label-align="right"
          label-class="pr-5"
          label-size="lg"
        >
          <b-form-checkbox
            class="mt-1"
            :checked="product.vat_rate === 0"
            @input="toggleDutyFree"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- 상세 정보들 -->
    <h4 style="margin-left: 10px;">
      상세 설명
    </h4>
    <b-row class="m-1 mb-2">
      <text-editor
        id="detail_html_content"
        v-model="product.detail_html_content"
        style="width:100%; max-height: 1000px;"
        :productIdx="product.idx"
        :updateImg="updateImg"
        @changeHtml="changeHtml"
      />
    </b-row>
    <b-row class="m-1 mb-2">
      <label
        for="description"
        style="font-size:16px;"
      >상품 정보</label>
      <b-form-textarea
        id="description"
        v-model="product.description"
        rows="6"
        placeholder="상세정보"
        @blur="isBlur"
      />
    </b-row>
    <b-row class="m-1 mb-2">
      <label
        for="delivery_content"
        style="font-size:16px;"
      >배송상세정보</label>
      <b-form-textarea
        id="delivery_content"
        v-model="product.delivery_content"
        rows="6"
        placeholder="배송상세정보"
        @blur="isBlur"
      />
    </b-row>
    <b-row class="m-1 mb-2">
      <label
        for="refund_content"
        style="font-size:16px;"
      >환불상세정보</label>
      <b-form-textarea
        id="refund_content"
        v-model="product.refund_content"
        rows="6"
        placeholder="환불상세정보"
        @blur="isBlur"
      />
    </b-row>
  </b-card>
</template>

<script>
import { defineComponent, ref, toRef } from '@vue/composition-api'

import {
  BFormInput, BFormTextarea, BTooltip, BInputGroup,
  BFormSelect, BCard, BRow, BCol, BImg, BFormGroup,
  BInputGroupAppend, VBModal, BButton, BModal, BTable,
  BFormCheckbox, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import axios from '@axios'

import TextEditor from './TextEditor.vue'
import SearchModal from '../SearchModal.vue'
import ShippingPolicyModal from './ShippingPolicyModal.vue'
import router from '@/router'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
      default: 'Product Detail',
    },
    product: Object,
    vendor: Object,
    categoryList: Array,
    buyLimitCond: Object,
    tagbyFeeRef: Object,
    baseOptions: Array,
    shippingFreeCond: Object,
    updateImg: Function,
    hasStartDate: Boolean,
    hasEndDate: Boolean,
    formattedPrice: String,
    formattedSupplyPrice: String,
    formattedMarketPrice: String,
    formattedShippingFee: String,
    formattedJejuShippingFee: String,
    formattedMountainShippingFee: String,
    formattedShippingFreeCond: String,
  },

  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BFormInput,
    BFormTextarea,
    BTooltip,
    BFormGroup,
    BInputGroup,
    BFormSelect,
    BInputGroupAppend,
    BButton,
    BModal,
    BTable,
    BPagination,
    BFormCheckbox,
    flatPickr,
    vSelect,
    TextEditor,
    SearchModal,
    ShippingPolicyModal,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup(props, context) {
    const vendor = toRef(props, 'vendor')
    const searchCategoryList = [
      { text: '회사명', value: 'company_name' },
      { text: '이름', value: 'name' },
      { text: '핸드폰번호', value: 'phone' },
    ]
    const searchResult = ref([])
    const searchResultColumns = [
      {
        key: 'idx',
        label: 'idx',
      },
      {
        key: 'company_name',
        label: '벤더 회사명',
      },
      {
        key: 'name',
        label: '벤더이름',
      },
      {
        key: 'phone',
        label: '핸드폰',
      },
      {
        key: 'select',
        label: '선택',
      },
    ]
    const searchVendorCount = ref(0)
    const isBusy = ref(false)
    const stateList = [
      { text: '판매중', value: 'ACTIVE' },
      { text: '승인대기중', value: 'CONFIRMING' },
      { text: '매진', value: 'SOLDOUT' },
      { text: '숨김', value: 'HIDDEN' },
      { text: '삭제', value: 'INACTIVE' },
      { text: '삭제 요청중', value: 'DELETING' },
      { text: '작성중', value: 'WRITING' },
    ]
    const sellTypeList = [
      { text: '상시판매', value: 'ALWAYS' },
      { text: '모집판매', value: 'RECRUITMENT' },
    ]
    const shippingPolicyList = ref([])
    const policyCount = ref(0)

    const copyText = text => {
      context.emit('copyText', text)
    }

    const searchVendor = queryParams => {
      isBusy.value = true
      axios.get(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/advertiser/search/`,
        { params: queryParams },
      ).then(res => {
        searchResult.value = res.data.data.data
        searchVendorCount.value = res.data.data.page_info.total_count
      }).catch(err => {
        console.log(err.data)
        alert('벤더정보를 불러오지 못하였습니다.')
      }).finally(
        isBusy.value = false,
      )
    }

    const changeVendor = vendorData => {
      context.emit('changeVendor', vendorData)
    }

    const getShippingPolicy = (page = 1, size = 10) => {
      const queryParams = {
        vendor_idx: vendor.value.idx,
        page,
        size,
      }
      axios.get(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/shipping-policy-list/`,
        { params: queryParams },
      ).then(res => {
        const result = res.data.data
        shippingPolicyList.value = result.data
        policyCount.value = result.page_info.total_count
      }).catch(error => {
        console.log(error.response.data)
      })
    }

    const changeShippingPolicy = selectedPolicy => {
      context.emit('changeShippingPolicy', selectedPolicy)
    }

    // blur & set value
    const setStartDate = bool => {
      if (bool === true) {
        context.emit('setStartDate')
      } else {
        context.emit('deleteStartDate')
      }
    }

    const setEndDate = bool => {
      if (bool === true) {
        context.emit('setEndDate')
      } else {
        context.emit('deleteEndDate')
      }
    }

    const setPrice = (e, key) => {
      context.emit('setPrice', e, key)
    }

    const setTagbyFeeRate = e => {
      context.emit('setTagbyFeeRate', e)
    }

    const setShippingFee = (e, key) => {
      context.emit('setShippingFee', e, key)
    }

    const toggleDutyFree = e => {
      context.emit('toggleDutyFree', e)
    }

    const isBlur = e => {
      e.target.classList.add('border-secondary')
    }

    const changeHtml = e => {
      context.emit('changeHtml', e)
    }

    const toAdvDetail = idx => {
      router.push({ name: 'member-adv-view', params: { idx } })
    }

    return {
      stateList,
      sellTypeList,

      copyText,
      setStartDate,
      setEndDate,
      setPrice,
      setTagbyFeeRate,
      setShippingFee,
      toggleDutyFree,
      isBlur,
      changeHtml,
      toAdvDetail,

      // modal
      searchResult,
      searchResultColumns,
      searchCategoryList,
      searchVendorCount,
      isBusy,
      searchVendor,
      changeVendor,

      // shipping policy modal
      shippingPolicyList,
      changeShippingPolicy,
      getShippingPolicy,
      policyCount,
    }
  },

})
</script>

<style lang="scss">
.vs__dropdown-toggle {
  min-width: 300px;
}

.invoice-filter-select {
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.vs__search, .vs__search:focus {
    appearance: none;
    line-height: 1.8;
    font-size: 1em;
    outline: none;
    width: 0;
    max-width: 100%;
    flex-grow: 1;
    z-index: 1;
}
</style>
