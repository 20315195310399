<template>
  <b-modal
    id="shipping-policy"
    size="lg"
    ok-title="적용"
    :ok-disabled="isCreateMode || !selectedPolicy"
    :cancel-title="'나가기'"
    @ok="changeShippingPolicy"
  >
    <b-card
      v-if="!isCreateMode"
      no-body
    >
      <b-row class="mb-2">
        <b-col
          align-self="center"
          style="font-size: 18px;"
          v-text="'합배송그룹'"
        />
        <b-col>
          <b-button
            class="float-right"
            @click="toCreateMode"
            v-text="'합배송 그룹 생성'"
          />
        </b-col>
      </b-row>
      <b-table
        :items="shippingPolicyList"
        :fields="shippingPolicyColumns"
        responsive
        small
      >
        <template #cell(additional_shipping_fee_cond)="data">
          {{ data.value }}
        </template>
        <template #cell(select)="data">
          <a
            style="color:#258BFF;"
            @click="selectPolicy(data.item)"
            v-text="'선택'"
          />
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="policyCount"
        per-page="10"
      />
      <b-row
        v-if="selectedPolicy"
        class="mt-1 ml-1"
      >선택된 그룹: {{ selectedPolicy.name }}
      </b-row>
    </b-card>
    <b-card
      v-else
    >
      <b-row>
        <b-col cols="6">
          <b-form-group label="그룹명">
            <b-form-input
              v-model="newPolicy.name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="최소/최대 부가">
            <v-select
              v-model="newPolicy.type"
              :options="policyTypeList"
              :reduce="policyType => policyType.value"
              placeholder="부과 방식 선택"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="제주 추가배송비">
            <b-form-input
              v-model.number="newPolicy.jeju"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="도서산간 추가배송비">
            <b-form-input
              v-model.number="newPolicy.island_mountain"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-button
          class="mr-1"
          @click="isCreateMode = false"
          v-text="'이전'"
        />
        <b-button
          variant="primary"
          @click="createShippingPolicy"
          v-text="'생성'"
        />
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import {
  VBModal, BTable, BRow, BCol, BButton,
  BCard, BFormGroup, BFormInput, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import axios from '@axios'

export default defineComponent({
  props: {
    vendorIdx: Number,
    shippingPolicyList: Array,
    policyCount: Number,
  },
  components: {
    VBModal,
    BTable,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormGroup,
    BFormInput,
    BPagination,
    vSelect,
  },

  setup(props, context) {
    const isCreateMode = ref(false)
    const toCreateMode = () => {
      isCreateMode.value = true
    }
    const policyTypeList = [
      { label: '최대부과', value: 'MAX' },
      { label: '최소부과', value: 'MIN' },
    ]
    const newPolicy = ref({
      name: '',
      type: 'MAX',
      jeju: 0,
      island_mountain: 0,
    })
    const shippingTypeFormatter = val => {
      if (val === 'MIN') {
        return '최소부과'
      } else if (val === 'MAX') {
        return '최대부과'
      } else {
        return '알수없음'
      }
    }
    const additionalShippingFeeCondFormatter = val => {
      const newVal = JSON.parse(val)
      return `제주: ${newVal.jeju ? newVal.jeju : 0} / 도서산간: ${newVal.island_mountain ? newVal.island_mountain : 0}`
    }
    const shippingPolicyColumns = [
      {
        key: 'idx',
        label: 'idx',
      },
      {
        key: 'name',
        label: '그룹 이름',
      },
      {
        key: 'type',
        label: '타입',
        formatter: val => shippingTypeFormatter(val),
      },
      {
        key: 'additional_shipping_fee_cond',
        label: '추가배송비',
        formatter: val => additionalShippingFeeCondFormatter(val),
      },
      {
        key: 'select',
        label: '선택',
      },
    ]
    const currentPage = ref(1)
    const selectedPolicy = ref()
    const selectPolicy = item => {
      selectedPolicy.value = item
    }

    const getShippingPolicy = (page=1, size=10) => {
      context.emit('getShippingPolicy', page, size)
    }

    watch(currentPage, () => {
      getShippingPolicy(currentPage.value, 10)
    })

    const createShippingPolicy = () => {
      const { name, type } = newPolicy.value
      let jeju;
      let island_mountain;
      try {
        jeju = Number.parseInt(newPolicy.value.jeju, 10)
        island_mountain = Number.parseInt(newPolicy.value.island_mountain, 10)
        if (Number.isNaN(jeju) || Number.isNaN(island_mountain)) {
          throw TypeError()
        }
        if (jeju < 0 || island_mountain < 0) {
          throw RangeError()
        }
      } catch {
        alert('추가배송비가 올바른 형식이 아닙니다.')
        return;
      }

      const additionalShippingFeeCond = {
        jeju,
        island_mountain,
      }
      const body = {
        name,
        type,
        additional_shipping_fee_cond: JSON.stringify(additionalShippingFeeCond),
        vendor_idx: props.vendorIdx,
      }
      axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/shipping-policy/`,
        body,
      ).then(() => {
        getShippingPolicy()
        isCreateMode.value = false
      }).catch(error => {
        alert(error.response)
        console.log(error.response)
      })
    }

    const changeShippingPolicy = () => {
      context.emit('changeShippingPolicy', selectedPolicy.value)
    }

    return {
      isCreateMode,
      policyTypeList,
      newPolicy,
      toCreateMode,
      createShippingPolicy,
      shippingPolicyColumns,
      currentPage,
      selectedPolicy,
      selectPolicy,
      changeShippingPolicy,
    }
  },
})
</script>

<style lang="scss">
.vs__dropdown-toggle {
  min-width: 300px;
}

.invoice-filter-select {
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.vs__search, .vs__search:focus {
    appearance: none;
    line-height: 1.8;
    font-size: 1em;
    outline: none;
    width: 0;
    max-width: 100%;
    flex-grow: 1;
    z-index: 1;
}
</style>