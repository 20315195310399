<template>
  <div class="editor">
    <div class="editor__header">
      <template v-for="(item, index) in headerOptions">
        <div :key="`divider${index}`">
          <div v-if="item.type === 'divider'" class="divider"></div>
          <button
            v-else
            v-bind="item"
            :key="index"
            class="header-option"
            :title="item.title"
            @click="item.action()"
          >
            <!-- <div v-if="item.icon === 'italic'" class="icon">
              <Italic />
            </div>

            <div v-if="item.icon === 'strikethrough'" class="icon">
              <StrikeIcon />
            </div>

            <div v-if="item.icon === 'code-view'" class="icon">
              <CodeIcon />
            </div>

            <div
              v-if="item.icon === 'h-1'"
              class="icon"
              style="width: 20px;"
            >
              <H1Icon />
            </div>
  
            <div
              v-if="item.icon === 'h-2'"
              class="icon"
              style="width: 20px;"
            >
              <H2Icon />
            </div>
  
            <div v-if="item.icon === 'separator'" class="icon">
              <HorizontalIcon />
            </div>
  
            <div
              v-if="item.icon === 'code-box-line'"
              class="icon"
              style="width: 20px;"
            >
              <CodeBlockIcon />
            </div> -->
  
            <div
              v-if="item.icon === 'image'"
              class="icon"
              style="width: 20px; height: 20px;"
            >
              <label for="fileInput"><ImageIcon /></label>
              <input
                @change="addImage"
                type="file"
                multiple
                id="fileInput"
                style="display:none;"
              />
            </div>

            <div
              v-if="item.icon === 'media'"
              class="icon"
              style="width: 20px; height: 20px;"
            >
              <MediaIcon />
            </div>

            <div
              v-if="item.icon === 'undo'"
              class="icon"
            >
              <UndoIcon />
            </div>

            <div
              v-if="item.icon === 'redo'"
              class="icon"
            >
              <RedoIcon />
            </div>
          </button>
        </div>
      </template>
      <button>
        <div
          class="icon"
          @click="toggleHtml"
        >
          HTML
        </div>
      </button>
    </div>
    <b-form-textarea
      v-if="isHtmlOpened === true"
      :value="value"
      @input="changeHtml"
    />
    <editor-content
      v-else
      class="editor__content"
      :editor="editor"
    />
</div>
</template>

<script>
import { defineComponent, onBeforeUnmount,
  onMounted, ref, watch,
} from '@vue/composition-api'
import { BFormTextarea } from 'bootstrap-vue'

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Iframe from './Iframe'
import Embed from './Embed'

// icon
import BoldIcon from '@/assets/images/icons/BoldIcon.vue'
import MediaIcon from '@/assets/images/icons/MediaIcon.vue'
import ImageIcon from '@/assets/images/icons/ImageIcon.vue'
import UndoIcon from '@/assets/images/icons/UndoIcon.vue'
import RedoIcon from '@/assets/images/icons/RedoIcon.vue'

export default defineComponent({
  components: {
    BFormTextarea,
    EditorContent,
    BoldIcon,
    MediaIcon,
    ImageIcon,
    UndoIcon,
    RedoIcon,
  },

  props: {
    productIdx: Number,
    value: {
      type: String,
    },
    updateImg: Function,
  },

  setup(props, context) {
    const editor = ref()
    const isHtmlOpened = ref(false)

    onMounted(() => {
      editor.value = new Editor({
        content: props.value,
        extensions: [
          StarterKit,
          Image.configure({
            inline: true,
            allowBase64: true,
          }),
          Iframe,
          Embed,
        ],
        onUpdate: () => {
        // HTML
          context.emit('input', editor.value.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
        },
      })
    })

    const headerOptions = ref([
      // {
      //   icon: 'bold',
      //   title: 'Bold',
      //   action: () =>
      //     editor.value
      //       .chain()
      //       .focus()
      //       .toggleBold()
      //       .run()
      // },
      {
        icon: 'image',
        title: 'image',
        action: () => {},
      },
      {
        icon: 'media',
        title: 'media',
        action: () => addIframe(),
      },
      {
        icon: 'undo',
        title: 'Undo',
        action: () =>
          editor.value
            .chain()
            .focus()
            .undo()
            .run(),
      },
      {
        icon: 'redo',
        title: 'Redo',
        action: () =>
          editor.value
            .chain()
            .focus()
            .redo()
            .run(),
      },
    ])

    const addImage = e => {
      const imgFiles = [...e.target.files]
      imgFiles.map(async imgFile => {
        const imgUrl = await props.updateImg(imgFile, 'DETAIL', props.productIdx)
        editor.value
          .chain()
          .focus()
          .setImage({ src: imgUrl })
          .run()
      })
    }

    const addIframe = () => {
      const url = window.prompt("URL");
      if (!url.includes('youtu')) {
        alert('유튜브 링크만 가능합니다')
        return;
      } else {
        const youtubeLinkCode = url.split('/').slice(-1)[0]
        const embeddedLink = `https://www.youtube.com/embed/${youtubeLinkCode}`
        editor.value
          .chain()
          .focus()
          .setIframe({ src: embeddedLink })
          .run()
      }
    }

    onBeforeUnmount(() => {
      editor.value.destroy()
    })

    watch(() => props.value, () => {
      const isSame = editor.value.getHTML() === props.value

      if (isSame) {
        return
      }

      editor.value.commands.setContent(props.value, false)
    })

    const toggleHtml = e => {
      if (isHtmlOpened.value === true) {
        e.target.classList.remove('html-active')
        isHtmlOpened.value = false
      } else {
        e.target.classList.add('html-active')
        isHtmlOpened.value = true
      }
    }

    const changeHtml = e => {
      context.emit('changeHtml', e)
    }

    return {
      isHtmlOpened,
      editor,
      headerOptions,
      addImage,
      toggleHtml,
      changeHtml,
    }
  }
})
</script>

<style lang="scss">
.editor {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: #fff;
  background-color: #2A3244;
  border: 1px solid #404756;
  border-radius: 0.75rem;
  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
    border-bottom: 1px solid #404756;
    button {
      background-color: #2A3244;
      border: none;
    }
  }
  &__content {
    height: 400px;
    padding: 1rem;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    resize: vertical;
    -webkit-overflow-scrolling: touch;
  }
  &__footer {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    white-space: nowrap;
    border-top: 3px solid #0d0d0d;
    font-size: 12px;
    font-weight: 600;
    color: #0d0d0d;
    white-space: nowrap;
    padding: 0.25rem 0.75rem;
  }
  &__status {
    display: flex;
    align-items: center;
    border-radius: 5px;
    &::before {
      content: " ";
      flex: 0 0 auto;
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      background: rgba(#0d0d0d, 0.5);
      border-radius: 50%;
      margin-right: 0.5rem;
    }
    &--connecting::before {
      background: #616161;
    }
    &--connected::before {
      background: #b9f18d;
    }
  }
  &__name {
    button {
      background: none;
      border: none;
      font: inherit;
      font-size: 12px;
      font-weight: 600;
      color: #0d0d0d;
      border-radius: 0.4rem;
      padding: 0.25rem 0.5rem;
      &:hover {
        color: #fff;
        background-color: #0d0d0d;
      }
    }
  }
}
p {
  line-height: 1.4;
}

.header-option {
  width: 32px;
  height: 40px;
  color: #0d0d0d;
  border: none;
  background-color: transparent;
  border-radius: 0.4rem;
  padding: 0.25rem;
  margin-right: 0.25rem;
  svg {
    width: 100%;
    height: 100%;
    fill: #B4B7BC;
  }
  &.is-active,
  &:hover {
    color: #fff;
    background-color: #0d0d0d;
  }
}

.html-active {
  color: #fff;
}
</style>