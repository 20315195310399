<template>
  <div class="crop-modal">
    <div class="tempImg" />
    <div>
      <vue-croppie
        ref="croppieRef" 
        :enableResize="false"
        :enableOrientation="true"
        :boundary="{ width: 100 + '%', height: 368 }"
      />
    </div>
    <div class="button-box">
      <b-button @click="cropImage">자르기</b-button>
      <b-button @click="closeModal">창닫기</b-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { BButton } from 'bootstrap-vue'

export default defineComponent({
  props: {
    idx: Number,
    originalImg: File,
    originalImgUrl: String,
  },

  components: {
    BButton,
  },

  setup(props, context) {
    const croppieRef = ref()

    const closeModal = () => {
      context.emit('closeModal')
    }

    const cropImage = () => {
      const options = {
        type: 'blob',
        size: { width: 700, height: 700 },
        format: 'png',
      }
      croppieRef.value.result(options, (output) => {
        let imgName = props.originalImg.name.split('.')[0]
        const randomString = Math.random().toString(36).substr(2, 6)
        imgName += `${randomString}.png`
        const croppedImgFile = new File(
          [output],
          imgName,
          { type: 'image/png' },
        )
        context.emit('updateImg', croppedImgFile, 'THUMBNAIL', props.idx)
        closeModal()
      })
    }

    onMounted(() => {
      croppieRef.value.bind({
        url: props.originalImgUrl,
      })
    })

    return {
      croppieRef,
      closeModal,
      cropImage,
    }
  }
})
</script>

<style lang="scss">
@import './cropImageModal.scss';

.crop-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  height: 60%;
  background-color:#293145;
  border: black solid 1px;
  border-radius: 10px;
  z-index: 2;

  margin-left: 100px;
}

.crop-modal .button-box {
  display: flex;
  justify-content: space-between;
  width: 180px;
}

</style>